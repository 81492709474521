<template lang="pug">
div.row
  ViewPhotoList(
    :sailorDocument="sailorDigitalDocument"
    documentType="sailorDigitalDocument"
    ).col-sm-12.col-md-6
  Medical(
    v-if="TYPE_DOCUMENTS_CONSTANTS.MEDICAL_CERTIFICATE === sailorDigitalDocument.type_document"
    @saveDocument="saveDocument").col-sm-12.col-md-6
  Qualification(
    v-if="[TYPE_DOCUMENTS_CONSTANTS.QUALIFICATION, TYPE_DOCUMENTS_CONSTANTS.PROOF_OF_DIPLOMA].includes(sailorDigitalDocument.type_document)"
    @saveDocument="saveDocument"
    :typeDocument="sailorDigitalDocument.type_document"
    :sailorId="sailorDigitalDocument.sailor.id").col-sm-12.col-md-6
  Experience(
    v-if="[TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE, TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD, TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD].includes(sailorDigitalDocument.type_document)"
    @saveDocument="saveDocument"
    :sailorId="sailorDigitalDocument.sailor.id"
    :typeDocument="sailorDigitalDocument.type_document"
    :files="sailorDigitalDocument.photo").col-sm-12.col-md-6
</template>
<script>
import { TYPE_DOCUMENTS_CONSTANTS, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { mapActions } from 'vuex'

export default {
  components: {
    Medical: () => import('./Forms/Medical.vue'),
    Qualification: () => import('./Forms/Qualification.vue'),
    Experience: () => import('./Forms/Experience.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList')
  },
  props: {
    sailorDigitalDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      TYPE_DOCUMENTS_CONSTANTS
    }
  },
  methods: {
    ...mapActions(['saveVerificationDocumentISC']),
    async saveDocument (item) {
      !item.data.body.type_override && (item.data.body.type_override = this.sailorDigitalDocument.type_document)
      item.data.body.additional_info.sailor = this.sailorDigitalDocument.sailor.id
      item.data.body.additional_info.status_document = STATUSES_DOCUMENT_CONSTANTS.draft
      item.data.body.additional_info.is_international = true
      const response = await this.saveVerificationDocumentISC(item)
      if ([200, 201].includes(response.code) && !this.sailorDigitalDocument) this.$parent.viewDetailedComponent(this.sailorDigitalDocument, 'viewInfoBlock')
    }
  }
}
</script>
